$product-img-max-width: 100%;
$product-img-max-height: 40vh;
$table-thumb-size: 1.5rem;

img {
  &.product-img {
    max-width: $product-img-max-width;
    width: auto;
    max-height: $product-img-max-height;
    height: auto;
    margin: auto;
    vertical-align: middle;
    display: block;
  }

  &.table-thumb {
    max-width: $table-thumb-size;
    width: auto;
    max-height: $table-thumb-size;
    height: auto;
    margin: auto;
    vertical-align: middle;
    display: inline-block;
  }
}
