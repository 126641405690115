.home-toc {
  position: fixed;
  right: 0;

  .toc-items {
    border-left: 1px solid $gray-300;
  }
}

.home-content {
  margin-right: 3rem;

  .logo img {
    max-height: 20vh;
    display: block;
    margin: auto auto 1rem;

    @include media-breakpoint-up(lg) {
      max-height: 15vh;
    }
  }

  .markdown {

    table {
      margin-bottom: 1rem;

      td {
        min-width: 8em;
      }
    }

    img {
      max-width: 100%;
      max-height: 25vh;
      display: block;
      padding: 0;
      margin: auto auto 0;

      @include media-breakpoint-up(md) {
        float: right;
        padding: 1rem;
      }

      @include media-breakpoint-up(lg) {
        max-height: 40vh;
      }
    }

  }
}
