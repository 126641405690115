// Define theme colors
// Scintilla colors
$scintilla-red: #E4002B;
$scintilla-blue: #1283B1;
$scintilla-orange: #E89611;
$scintilla-black: #28282A;
$scintilla-white: #EBEBEB;

// Other colors
$full-white: #FFFFFF;
$spanish-gray: #999999;
$ut-green: #3F9C35;

// Set bootstrap theme colors
$theme-colors: (
        'primary': $scintilla-red,
        'secondary': $scintilla-blue,
        'light': $scintilla-white,
        'dark': $scintilla-black,
        'warm': $spanish-gray,
        'success': $ut-green,
        'warning': $scintilla-orange,
        'danger': $scintilla-red,
        'full-white': $full-white,
);

// Set default colors
$black: $scintilla-black;

// Admin LTE sidebar colors
$sidebar-dark-bg: $scintilla-red;
$sidebar-dark-color: $scintilla-white;
$sidebar-dark-hover-bg: $scintilla-white;
$sidebar-dark-hover-color: $scintilla-black;

$sidebar-dark-submenu-color: $scintilla-white;
$sidebar-dark-submenu-hover-bg: $scintilla-white;
$sidebar-dark-submenu-hover-color: $scintilla-black;
$sidebar-dark-submenu-active-bg: $full-white;
$sidebar-dark-submenu-active-color: $scintilla-black;

// Admin LTE main background color
$main-bg: $full-white;
