@mixin table-shrink {
  th, td {
    &.shrink, &.actions {
      width: 1px;
    }

    &.actions {
      white-space: nowrap;
    }
  }
}

table.table {
  @each $breakpoint, $size in $grid-breakpoints {
    &.b-table-stacked-#{$breakpoint} {
      @include media-breakpoint-up($breakpoint) {
        @include table-shrink
      }
    }
  }

  &:not(.b-table-stacked-xs):not(.b-table-stacked-md):not(.b-table-stacked-lg):not(.b-table-stacked-xl) {
    @include table-shrink
  }

  th {
    .table-header-icon {
      text-align: center;
    }
  }

  td {
    .btn:not(.normal) {
      $line-height: 1.2;
      @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $line-height, $btn-border-radius-sm);
      border: 0;

      .fas, .far {
        line-height: $line-height;
      }
    }
  }
}
