@use 'sass:list';

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.pointer {
  cursor: pointer;
}

.mx-datepicker {
  font: list.slash($input-font-size, $input-line-height) $font-family-base !important;

  .mx-input {
    height: unset;
    padding: $input-padding-y $input-padding-x;
    font-size: $input-font-size;
    line-height: $input-line-height;
  }
}
