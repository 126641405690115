html {
  scroll-padding-top: calc(4rem + 1px);
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}

.flex {
  display: flex;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

div.content-wrapper {
  padding: $spacer*0.5 $spacer;

  @include media-breakpoint-down(md){
    padding: $spacer*0.25 $spacer*0.5;
  }

  .content-header {
    padding: 0 0 $spacer*0.5;
  }

  .container-fluid {
    padding: 0 !important;
  }

  & > section.content {
    padding: 0 !important;
  }
}

:focus {
  outline: 0;
}

.clickable {
  cursor: pointer;
}

.animate-rotation {
  transition-duration: 0.5s;
  transition-property: transform;
}
