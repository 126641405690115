.modal {
  .modal-dialog {
    &.fill {
      @include media-breakpoint-between(sm, lg) {
        max-width: 100% !important;
        margin: $modal-dialog-margin;
      }
    }
  }

  .modal-footer {
    @each $number, $percentage in $sizes {
      @if type-of($number) == 'number' and $number != 100 {
        .btn.w-#{$number} {
          width: calc(#{$percentage} - #{$modal-footer-margin-between}) !important;
        }
      }
    }
  }
}
