form {
  label, legend {
    font-weight: bold;

    &.required:not(.custom-control-label) {
      &::after {
        content: '*';
        color: $scintilla-red;
        padding-left: $spacer * 0.25;
      }
    }
  }

  .custom-control {
    label, legend {
      &.custom-control-label {
        font-weight: normal;
      }
    }
  }

  // Hide number input controls
  input[type=number] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .custom-control-container {
    display: flex;

    .left {
      width: $custom-control-gutter + $custom-control-indicator-size;
    }
  }
}

.text-right {
  input {
    text-align: right;
  }
}

.input-group-prepend {
  select {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.input-group-append {
  select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

select {
  option {
    &.placeholder {
      color: $spanish-gray;
    }
  }
}
